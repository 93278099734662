import { createSlice } from "@reduxjs/toolkit";

import { IupchargeResponse } from "../../typings/interfaces/responses";
import { getAllUpchargesAction } from "../actions/upchargesAction";

export interface Upchargestate{
    upcharges: IupchargeResponse[] | null;
    upchargesLoading: boolean;
    upchargesError: string | null;
}

const initialState: Upchargestate = {
    upcharges: null,
    upchargesLoading: false,
    upchargesError: null,
}

const upchargesSlice = createSlice({
    name: "upcharges",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUpchargesAction.pending, (state) => {
                state.upcharges = null;
                state.upchargesLoading = true;
                state.upchargesError = null;
            })
            .addCase(getAllUpchargesAction.fulfilled, (state, action) => {
                state.upchargesError = null;
                state.upchargesLoading = false;
                state.upcharges = (action.payload as any).body;
            })
            .addCase(getAllUpchargesAction.rejected, (state, action) => {
                state.upcharges = null;
                state.upchargesLoading = false;
                state.upchargesError = (action.payload as any).message;
            })         
    }
});

export default upchargesSlice.reducer;
export const upchargeSliceActions = upchargesSlice.actions;