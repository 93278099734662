import { createSlice } from "@reduxjs/toolkit";

import { IspotResponse } from "../../typings/interfaces/responses";
import { getAllSpotsAction } from "../actions/spotsAction";

export interface Spotstate{
    spots: IspotResponse[] | null;
    spotsLoading: boolean;
    spotsError: string | null;
}

const initialState: Spotstate = {
    spots: null,
    spotsLoading: false,
    spotsError: null,
}

const spotsSlice = createSlice({
    name: "spots",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllSpotsAction.pending, (state) => {
                state.spots = null;
                state.spotsLoading = true;
                state.spotsError = null;
            })
            .addCase(getAllSpotsAction.fulfilled, (state, action) => {
                state.spotsError = null;
                state.spotsLoading = false;
                state.spots = (action.payload as any).body;
            })
            .addCase(getAllSpotsAction.rejected, (state, action) => {
                state.spots = null;
                state.spotsLoading = false;
                state.spotsError = (action.payload as any).message;
            })         
    }
});

export default spotsSlice.reducer;
export const spotSliceActions = spotsSlice.actions;