import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponseSingle } from "../../typings/interfaces";
import { IcustomerInsightResponse } from "../../typings/interfaces/responses";
import { GET_CUSTOMER_INSIGHTS } from "../../configs/apiConfig";

export const getAllCustomerInsightsAction = createAsyncThunk(
    "customerInsights/all-customerInsights",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await axios.get<BaseResponseSingle<IcustomerInsightResponse>>(
                GET_CUSTOMER_INSIGHTS(id)
            );
            return response.data;
        }
        catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
)
