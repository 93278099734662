import { FC, useEffect, useState } from "react";
import { Grid, Box, CircularProgress } from "@mui/material";

import CustomerHistoryTableRow from "./customer-history-table-row";
import { Colors } from "../../../../configs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getCustomerBillHistoryAction } from "../../../../redux/actions/customerAction";
import InvoiceHistoryModal from "../../../pos/invoice-history-modal/invoice-history-modal";
import { useParams } from "react-router-dom";
import { CustomerBillsResponse } from "../../../../typings";
import {
  addSession,
  getBillByIdAction,
  updateCartSessionItem,
} from "../../../../redux/actions/cartAction";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { ICartSession } from "../../../../typings/interfaces/pos/cart-session";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";

export interface ICustomerHistoryTableProps {
  contentHeight?: string;
}

const CustomerHistoryTable: FC<ICustomerHistoryTableProps> = ({
  contentHeight = "39.07rem",
}) => {
  const params = useParams();
  const customerId = params.id;
  const queryParams = new URLSearchParams(window.location.search);
  const billId = queryParams.get("billId");
  const dispatch = useAppDispatch();
  const { bills, billsLoading } = useAppSelector((state) => state.customer);
  const [clickedRow, setClickedRow] = useState<CustomerBillsResponse | null>(
    null
  );
  const snackbar = useSnackbarContext();

  const [openModal, setOpenModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] =
    useState<CustomerBillsResponse | null>(null);

  // useEffect(() => {
  //   if (billId)
  //     setClickedRow({
  //       billId: billId,
  //       id: Number(billId),
  //     });
  // }, [billId]);
  // const sampleBillSession: ICartSession = {
  //   sessionId: "cf1f23f5-23cc-44cd-ba49-2561b9641a66",
  //   cartItems: [
  //     {
  //       serviceType: {
  //         id: 16,
  //         categoryName: "Household",
  //         showInMenu: true,
  //         deleted: false,
  //       },
  //       garmentType: { name: "Carpet", id: 18, icon: "CarpetImage.png" },
  //       materialType: { name: "Camel", id: 112 },
  //       garmentStyle: { name: "Bathroom - Large", id: 107 },
  //       qty: 1,
  //       itemId: "7b83991a-04f1-4de6-9507-63f2e532692d",
  //       finalized: true,
  //       unitPrice: 33,
  //       instructions: [],
  //       damages: [],
  //       upcharges: [],
  //       spots: [],
  //       alterationItems: [],
  //       note: null,
  //     },
  //     {
  //       serviceType: {
  //         id: 16,
  //         categoryName: "Household",
  //         showInMenu: true,
  //         deleted: false,
  //       },
  //       garmentType: {
  //         name: "Duvet Cover",
  //         id: 26,
  //         icon: "DuvetCoverImage.png",
  //       },
  //       materialType: { name: "Chenille", id: 109 },
  //       garmentStyle: { name: "Heavy", id: 66 },
  //       qty: 1,
  //       itemId: "1485ab90-36fe-4f24-b237-de7750331fb6",
  //       finalized: true,
  //       unitPrice: 35.75,
  //       instructions: [],
  //       damages: [],
  //       upcharges: [],
  //       spots: [],
  //       alterationItems: [],
  //       note: null,
  //     },
  //     {
  //       serviceType: {
  //         id: 16,
  //         categoryName: "Household",
  //         showInMenu: true,
  //         deleted: false,
  //       },
  //       garmentType: {
  //         name: "Place Mats",
  //         id: 34,
  //         icon: "PlaceMatImage.png",
  //       },
  //       materialType: { name: "Camel", id: 112 },
  //       garmentStyle: { name: "Fancy / Heavy", id: 82 },
  //       qty: 1,
  //       itemId: "a2e9fdf0-09ff-4dd1-a63f-82d49017c1b9",
  //       finalized: true,
  //       unitPrice: 6.5,
  //       instructions: [],
  //       damages: [],
  //       upcharges: [],
  //       spots: [],
  //       alterationItems: [],
  //       note: null,
  //     },
  //   ],
  //   customerId: 348,
  //   pinTag: {
  //     color: "TU",
  //     tags: ["20", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  //   },
  // };

  const handleOnClickRow = (data: CustomerBillsResponse) => {
    setClickedRow(data);
    dispatch(CartSliceAction.clearSession());
    if (data.billString) {
      dispatch(addSession(JSON.parse(data.billString)));
    } else {
      snackbar.showSnackbar("cart not found for the bill", "error");
    }
  };

  useEffect(() => {
    if (billId) {
      setClickedRow({
        billId: billId,
        id: Number(billId),
      });
    }
  }, [billId]);

  useEffect(() => {
    if (clickedRow?.id) {
      dispatch(getBillByIdAction({ billId: String(clickedRow?.id) }));
    }
  }, [clickedRow]);

  useEffect(() => {
    getCustommerBillList();
  }, [customerId]);

  const getCustommerBillList = () => {
    if (customerId) dispatch(getCustomerBillHistoryAction(customerId));
  };
  const styles = {
    tableHeadingCell: {
      // borderRadius: "0.71rem",
      borderRadius: "0",
      background: Colors.SECONDARY,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem 0.71rem",
      boxSizing: "border-box",
      color: "white",
      height: "2.92857142857rem",
    },
  };

  const handleViewClick = (data: CustomerBillsResponse) => {
    setSelectedInvoice(data);
    setOpenModal(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0",
        padding: "0.71rem",
        gap: "1.14rem",
        height: "28.42rem",
      }}
    >
      <Box>
        <Grid container sx={{ gap: "0.21rem", height: "100%" }}>
          <Grid item xs={12}>
            <Box style={{ position: "sticky", top: 0 }}>
              <Grid container sx={{ gap: "0.15rem", flexWrap: "nowrap" }}>
                <Grid
                  item
                  xs={1}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "2.92857142857rem",
                    maxWidth: "2.92857142857rem !important",
                    borderTopLeftRadius: "0.71rem",
                  }}
                >
                  &nbsp;
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Invoice
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Date In
                </Grid>
                {/* cashier column */}
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Cashier
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Due date
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pieces
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "4.606rem",
                    maxWidth: "4.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Rack
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.606rem",
                    maxWidth: "5.606rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Balance
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pay Date
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "7.826rem",
                    maxWidth: "7.826rem !important",
                    fontSize: "1rem",
                  }}
                >
                  Pickup Date
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{
                    ...styles.tableHeadingCell,
                    minWidth: "5.246rem",
                    maxWidth: "5.246rem !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopRightRadius: "0.71rem",
                  }}
                >
                  <TouchAppIcon sx={{ color: "white" }} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ height: contentHeight }}>
            <Box
              sx={{
                height: contentHeight,
                overflowY: "auto",
                overflowX: "hidden",
                "&::-webkit-scrollbar": { width: "0.5rem" },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ADADAD",
                  borderRadius: "0rem",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  borderRadius: "0rem",
                },
              }}
            >
              {[...(bills || [])].reverse().map((item, index) => (
                <CustomerHistoryTableRow
                  isSelected={clickedRow?.id === item.id}
                  data={item}
                  firstRow={index === 0}
                  lastRow={index === (bills || []).length - 1}
                  onClick={handleOnClickRow}
                  onViewClick={handleViewClick}
                />
              ))}
              {billsLoading && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    sx={{
                      mx: "auto",
                      mt: "7rem",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Render InvoiceHistoryModal */}
      {selectedInvoice && (
        <InvoiceHistoryModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          // invoiceData={selectedInvoice}
          billId={selectedInvoice.billId}
        />
      )}
    </Box>
  );
};

export default CustomerHistoryTable;
