import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponse } from "../../typings/interfaces";
import { IupchargeResponse } from "../../typings/interfaces/responses";
import { GET_ALL_UPCHARGES } from "../../configs/apiConfig";

export const getAllUpchargesAction = createAsyncThunk(
    "upcharges/all-upcharges",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get<BaseResponse<IupchargeResponse>>(
                GET_ALL_UPCHARGES
            );
            return response.data;
        }
        catch (err) {
            if (!err) {
                throw err;
            }
            return rejectWithValue(err);
        }
    }
)