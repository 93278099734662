import React, { FC, useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { cloneDeep } from "lodash";
import { Swiper, SwiperSlide } from "swiper/react";

import { Colors } from "../../../../configs";
import { CustomButton } from "../../../atoms";
import { sortArray } from "../../../../util/array-functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { ISelectedUpcharge } from "../../../../typings/interfaces/pos/upCharge";
import UpchargesModal from "../../../pos/upcharges-modal/upchargesModal";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";
import { getAllUpchargesAction } from "../../../../redux/actions/upchargesAction";
import { IupchargeResponse } from "../../../../typings";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";

export interface IUpchargesSectionProps {}

// const upcharges: IUpcharge[] = IupchargeResponse;

const UpchargesSection: FC<IUpchargesSectionProps> = () => {
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const snackbar = useSnackbarContext();

  const { upcharges, upchargesError, upchargesLoading } = useAppSelector(
    (state) => state.upcharge
  );

  useEffect(() => {
    dispatch(getAllUpchargesAction());
  }, []);

  useEffect(() => {
    if (upchargesError) {
      snackbar.showSnackbar(upchargesError, "error");
    }
  }, [upchargesError]);

  const allUpcharges = [
    ...(upcharges || []),
    {
      id: 100000,
      merchantId: 1,
      storetId: 1,
      upcharge: "Custom",
      upchargeVariants: [
        {
          id: 100000,
          upchargeId: 1,
          upchargeVariant: "Custom",
          price: 0,
          deleted: false,
        },
      ],
      deleted: false,
    },
  ];

  const upchargesSorted = sortArray({
    array: allUpcharges,
    sortByKey: "upcharge",
    fixedFirstItem: "Custom",
  });

  const [upchargeModalOpen, setUpchargeModalOpen] = useState<{
    open: boolean;
    upcharge: IupchargeResponse | null;
  }>({
    open: false,
    upcharge: null,
  });

  const handleOnClickUpcharge = (upcharge: IupchargeResponse) => {
    setUpchargeModalOpen({
      open: true,
      upcharge: upcharge,
    });
  };
  const handleCloseUpchargeModal = () => {
    setUpchargeModalOpen({
      open: false,
      upcharge: null,
    });
  };
  const handleAddUpcharge = (upcharge: ISelectedUpcharge) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      if (
        cartItem.upcharges.find(
          (upchargeItem) => upchargeItem.id === upcharge.id
        ) === undefined
      ) {
        // Add new upcharge
        cartItem.upcharges.push(upcharge);
      } else {
        // Update existing upcharge
        cartItem.upcharges = cartItem.upcharges.map((upchargeItem) =>
          upchargeItem.id === upcharge.id ? upcharge : upchargeItem
        );
      }
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };
  const handleRemoveUpcharge = (upcharge: ISelectedUpcharge) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      cartItem.upcharges = cartItem.upcharges.filter(
        (upchargeItem) => upchargeItem.id !== upcharge.id
      );
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.71428571rem",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        padding: "0.928571429rem",
      }}
    >
      {upchargeModalOpen.upcharge && (
        <UpchargesModal
          open={upchargeModalOpen.open}
          onClose={handleCloseUpchargeModal}
          upcharge={upchargeModalOpen.upcharge}
          onAdd={handleAddUpcharge}
          onRemove={handleRemoveUpcharge}
        />
      )}

      <Typography
        sx={{ fontSize: "1.71428571rem", color: Colors.TEXT_GRAY_DARK }}
      >
        Upcharges
      </Typography>
      <Swiper
        style={{ width: "72rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(upchargesSorted.length / 36))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                  width: "72rem",
                  gap: "1.07142857rem",
                  rowGap: "0.857142857rem",
                  alignContent: "flex-start",
                  height: "46.7142857rem",
                }}
              >
                {upchargesLoading ? (
                  <CircularProgress sx={{ mx: "auto" }} />
                ) : (
                  upchargesSorted
                    .slice(swipePageIndex * 36, (swipePageIndex + 1) * 36)
                    .map((upcharge, i) => (
                      <CustomButton
                        sx={{
                          width: "17.0064286rem",
                          background:
                            (selectedCartItem?.upcharges.filter(
                              (cartUpcharge) =>
                                cartUpcharge.id ===
                                (upcharge as IupchargeResponse).id
                            ).length ?? 0) > 0
                              ? Colors.SECONDARY
                              : Colors.MAIN_GRAY,
                          borderRadius: "0.71rem",
                          textTransform: "capitalize",
                          color: Colors.WHITE,
                          fontSize: "1.14rem",
                          lineHeight: "1.33928571rem",
                          padding: "1.07142857rem 0.857142857rem",
                          "&&:hover": {
                            background: Colors.TEXT_GRAY_DARK,
                          },
                          height: "4.28571429rem",
                        }}
                        key={i}
                        onClick={() =>
                          handleOnClickUpcharge(upcharge as IupchargeResponse)
                        }
                      >
                        {(upcharge as IupchargeResponse).upcharge}
                      </CustomButton>
                    ))
                )}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default UpchargesSection;
