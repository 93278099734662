import { ChangeEvent, FC, useEffect, useState } from "react";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import { Colors } from "../../../../configs";
import {
  CartItem,
  IDamage,
  IInstruction,
} from "../../../../typings/interfaces/pos/cart-item";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { CartSliceAction } from "../../../../redux/slice/cartSlice";
import { cloneDeep, startCase, upperFirst } from "lodash";
import CrossIcon from "../../../../assets/icons/cross-icon";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";
import { CustomTextBox } from "../../../atoms";
import GarmentColor from "../../../../typings/enum/color";
import { ISelectedUpcharge } from "../../../../typings/interfaces/pos/upCharge";
import { ISelectedSpot } from "../../../../typings/interfaces/pos/spot";
import { formatCurrency } from "../../../../util/common-functions";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import ItemNoteModal from "../../../pos/notes-modal/itemNoteModal";

export interface ICartRowProps {
  // data: {
  // qty: number;
  // serviceType: string;
  // textureType: string;
  // color: GarmentColor[];
  // clothSubType: string;
  // materialType: string;
  // clothType: string;
  // instructions: IInstruction[];
  // damages: IDamage[];
  // upcharges: ISelectedUpcharge[];
  // unitPrice: number;
  // };
  status: "In Progress" | "Completed" | "Cancelled";
  firstRow: boolean;
  lastRow: boolean;
  onSelect(): void;
  cartItem: CartItem;
  alterationUnitPrice?: number;
}

const CartRow: FC<ICartRowProps> = ({
  alterationUnitPrice,
  status,
  firstRow,
  lastRow,
  cartItem,
}) => {
  const [unitPrice, setUnitPrice] = useState<number>(
    Number(cartItem.unitPrice.toFixed(2))
  );
  const [alterationUnitPriceState, setAlterationUnitPriceState] = useState<
    number | undefined
  >(Number(alterationUnitPrice?.toFixed(2)));
  const [itemNoteModalOpen, setItemNoteModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setUnitPrice(cartItem.unitPrice);
  }, [cartItem.unitPrice]);

  useEffect(() => {
    setAlterationUnitPriceState(alterationUnitPrice);
  }, [alterationUnitPrice]);

  const dispatch = useAppDispatch();
  const { selectedCartItem } = useAppSelector((state) => state.cart);

  const handleOnSelectCartItem = () => {
    if (selectedCartItem?.itemId === cartItem.itemId) {
      dispatch(CartSliceAction.setSelectedCartItem(null));
      return;
    }
    dispatch(CartSliceAction.setSelectedCartItem(cartItem));
  };

  const dcSelectionText = [
    cartItem?.garmentType?.name || "",
    cartItem?.garmentStyle?.name || "",
    startCase((cartItem.materialType?.name || "").toLowerCase()),
  ];
  const dcColorAndTextureText = [
    (cartItem.garmentColor || [])
      .map((color) => upperFirst(color.toLowerCase()))
      .join(", "),
    upperFirst((cartItem.texture || "").toLowerCase()),
  ];

  // const instructions = cartItem.instructions.map((item) =>
  //   upperFirst(item.label.toLowerCase())
  // );
  // const damages = cartItem.damages.map((item) =>
  //   upperFirst(item.label.toLowerCase())
  // );

  const styles = {
    tableBodyCell: {
      background:
        selectedCartItem?.itemId === cartItem.itemId
          ? Colors.ACCENT_GRAY_DISABLED
          : Colors.ACCENT_GRAY,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1.07rem 1.28rem",
      paddingBottom: "0rem",
      boxSizing: "border-box",
      gap: "1.07rem",
    },
    descriptionCell: {
      alignItems: "flex-start",
    },
    firstRowCell: {
      borderTopLeftRadius: "0.71rem",
      borderTopRightRadius: "0.71rem",
    },
    lastRowCell: {
      borderBottomRightRadius: "0.71rem",
      borderBottomLeftRadius: "0.71rem",
      paddingBottom: "1.07rem",
    },
    tableRow: {},
  };

  const getPrice = (mode: "alteration" | "other" = "other") => {
    if (
      (cartItem?.serviceType?.categoryName === "Alteration" &&
        alterationUnitPriceState) ||
      mode === "alteration"
    ) {
      return formatCurrency({
        amount: cartItem.qty * (alterationUnitPriceState || 0),
      });
      // cartItem.qty * (alterationUnitPrice || 0)
      //   ? (cartItem.qty * (alterationUnitPrice || 0)).toFixed(2).toLocaleString()
      //   : "N/A";
    } else {
      return formatCurrency({ amount: cartItem.qty * unitPrice });
      // cartItem.qty * unitPrice
      //   ? (cartItem.qty * unitPrice).toFixed(2).toLocaleString()
      //   : "N/A";
    }
  };

  const handleUnitPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setUnitPrice(newValue);
  };

  const getUnitPrice = (mode: "alteration" | "other" = "other") => {
    if (
      (cartItem?.serviceType?.categoryName === "Alteration" &&
        alterationUnitPriceState) ||
      mode === "alteration"
    ) {
      return formatCurrency({ amount: alterationUnitPriceState ?? 0 });
    } else {
      return formatCurrency({ amount: unitPrice ?? 0 });
    }
  };

  const removeAlteration = (index: number) => {
    let clonedCartItem = cloneDeep(cartItem);
    if (clonedCartItem) {
      clonedCartItem.alterationItems.splice(index, 1);
      dispatch(updateCartSessionItem({ cartItem: clonedCartItem }));
    }
  };

  const handleRemoveUpcharge = (upcharge: ISelectedUpcharge) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      cartItem.upcharges = cartItem.upcharges.filter(
        (upchargeItem) => upchargeItem.id !== upcharge.id
      );
      //   cartItem.unitPrice -= upcharge.price;
      // price isn't updating when dispatching
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };
  const handleRemoveSpot = (spot: ISelectedSpot) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      cartItem.spots = cartItem.spots.filter(
        (spotItem) => spotItem.id !== spot.id
      );
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };

  return (
    <>
      <ItemNoteModal
        open={itemNoteModalOpen}
        onClose={() => setItemNoteModalOpen(false)}
        cartItem={cartItem}
      />
      <Grid
        container
        sx={{ gap: "0.35rem", flexWrap: "nowrap" }}
        onClick={handleOnSelectCartItem}
      >
        <Grid
          item
          xs={1}
          sx={{
            ...styles.tableBodyCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
          }}
        >
          <Typography>{cartItem.qty}</Typography>
          <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
              zIndex: 2,
            }}
          ></Box>
        </Grid>
        <Grid
          item
          xs={6.7}
          sx={{
            ...styles.tableBodyCell,
            ...styles.descriptionCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
            position: "relative",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "0rem",
              right: "0rem",
              display:
                selectedCartItem?.itemId !== cartItem.itemId
                  ? cartItem.note !== null
                    ? "block"
                    : "none"
                  : "block",
            }}
            onClick={(e) => {
              if (selectedCartItem?.itemId !== cartItem.itemId) {
                dispatch(CartSliceAction.setSelectedCartItem(cartItem));
              }
              setItemNoteModalOpen(true);
              e.preventDefault();
              e.stopPropagation();
            }}
            disabled={
              selectedCartItem?.itemId !== cartItem.itemId
                ? cartItem.note !== null
                  ? false
                  : true
                : false
            }
          >
            <SpeakerNotesIcon
              sx={{
                color:
                  cartItem.note === null ? Colors.MAIN_GRAY : Colors.PRIMARY,
              }}
            />
          </IconButton>
          <Box sx={{ width: "100%" }}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: 12,
                }}
              >
                <Typography fontWeight="bold">
                  {cartItem?.serviceType?.categoryName || ""}
                </Typography>
              </Box>
              <Typography>
                {dcSelectionText.filter((text) => text !== "").join(", ")}
              </Typography>
              <Typography>
                {dcColorAndTextureText.filter((text) => text !== "").join(", ")}
              </Typography>
              {cartItem.alterationItems &&
                cartItem.alterationItems.length > 0 && (
                  <>
                    {cartItem.alterationItems.map((item, index) => (
                      <Box
                        key={index}
                        sx={{ display: "flex", alignItems: "center", mt: 1 }}
                      >
                        <Box>
                          <Typography>+ </Typography>
                        </Box>
                        <Typography>
                          Alterations -&nbsp;
                          {item.alterationSubCategory?.name || "No Subcategory"}
                        </Typography>
                        <Typography sx={{ ml: 1 }}>
                          {item?.alterationPrice?.name ||
                            "No Second Sub Category"}
                        </Typography>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            removeAlteration(index);
                          }}
                          sx={{ ml: 1 }}
                        >
                          <CrossIcon />
                        </IconButton>
                      </Box>
                    ))}
                  </>
                )}
              {cartItem.upcharges && cartItem.upcharges.length > 0 && (
                <>
                  {cartItem.upcharges.map((upcharge, index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", alignItems: "center", mt: 1 }}
                    >
                      <Box>
                        <Typography>+ </Typography>
                      </Box>
                      <Typography>
                        Upcharges -&nbsp;{upcharge.label} (
                        {upcharge.subUpcharge.upchargeVariant})
                      </Typography>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveUpcharge(upcharge);
                        }}
                        sx={{ ml: 1 }}
                      >
                        <CrossIcon />
                      </IconButton>
                    </Box>
                  ))}
                </>
              )}
              {cartItem.spots && cartItem.spots.length > 0 && (
                <>
                  {cartItem.spots.map((spot, index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", alignItems: "center", mt: 1 }}
                    >
                      <Box>
                        <Typography>+ </Typography>
                      </Box>
                      <Typography>
                        Spots -&nbsp;{spot.label}{" "}
                        {spot.subSpot.comment
                          ? `(${spot.subSpot.comment})`
                          : ""}
                      </Typography>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveSpot(spot);
                        }}
                        sx={{ ml: 1 }}
                      >
                        <CrossIcon />
                      </IconButton>
                    </Box>
                  ))}
                </>
              )}
            </Box>
            {cartItem.instructions.length > 0 && (
              <Box sx={{ display: "flex" }}>
                <Box>
                  <Typography>+ </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Instructions -&nbsp;
                    {cartItem.instructions
                      .map((instruction, i) => {
                        return upperFirst(instruction.label.toLowerCase());
                      })
                      .join(", ")}
                  </Typography>
                </Box>
              </Box>
            )}
            {cartItem.damages.length > 0 && (
              <Box sx={{ display: "flex" }}>
                <Box>
                  <Typography>+ </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Damages -&nbsp;
                    {cartItem.damages
                      .map((damage, i) => {
                        return upperFirst(damage.label.toLowerCase());
                      })
                      .join(", ")}
                  </Typography>
                </Box>
              </Box>
            )}

            {cartItem.note && (
              <Box sx={{ display: "flex" }}>
                <Box>
                  <Typography>+ </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Note -&nbsp;
                    {upperFirst(cartItem.note.toLowerCase())}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
              zIndex: 2,
            }}
          ></Box>
        </Grid>
        <Grid
          item
          xs={1.7}
          sx={{
            ...styles.tableBodyCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
          }}
        >
          <Typography>{status}</Typography>
          <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
              zIndex: 2,
            }}
          ></Box>
        </Grid>
        <Grid
          item
          xs={1.3}
          sx={{
            ...styles.tableBodyCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
          }}
        >
          <CustomTextBox
            styles={{
              padding: "0",
              paddingInline: "0",
              paddingBlock: "0",
              background: "none",
              border: "none",
              cursor: "text",
              height: "1.5rem",
              width: "100%",
              fontSize: "1rem",
              textAlign: "center",
              outline: "none",
            }}
            onClick={(e) => e.stopPropagation()}
            value={getUnitPrice()}
            type="currency"
            onChange={handleUnitPriceChange}
          />
          {cartItem.alterationItems.map((item, index) => (
            <Box sx={{ mt: "0.571428571rem" }} key={index}>
              <CustomTextBox
                sx={{
                  padding: "0 !important",
                  marginInline: "-1rem !important",
                  background: "none",
                  border: "none",
                  cursor: "text",
                  "& input": {
                    border: "none !important",
                    outline: "none !important",
                    padding: "0 !important",
                    paddingBlock: "0 !important",
                    height: "1.5rem !important",
                    textAlign: "center",
                  },
                  "& *": {
                    border: "none !important",
                    background: "none !important",
                    outline: "none !important",
                  },
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                value={formatCurrency({ amount: item.unitPrice ?? 0 })}
                type="number"
                inputProps={{
                  step: "0.01",
                }}
              />
            </Box>
          ))}
          {cartItem.upcharges.map((upcharge, index) => (
            <Box sx={{ mt: "0.571428571rem" }} key={index}>
              <Typography>
                {formatCurrency({
                  amount: upcharge.subUpcharge.price ?? 0,
                })}
              </Typography>
            </Box>
          ))}
          {cartItem.spots.map((spot, index) => (
            <Box sx={{ mt: "0.571428571rem" }} key={index}>
              <Typography>
                {formatCurrency({
                  amount: spot.subSpot.price ?? 0,
                })}
              </Typography>
            </Box>
          ))}
          {cartItem.instructions.length > 0 && (
            <Box sx={{ display: "flex" }}>&nbsp;</Box>
          )}
          {cartItem.damages.length > 0 && (
            <Box sx={{ display: "flex" }}>&nbsp;</Box>
          )}
          <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
              zIndex: 2,
            }}
          ></Box>
        </Grid>
        <Grid
          item
          xs={1.3}
          sx={{
            ...styles.tableBodyCell,
            ...(firstRow ? styles.firstRowCell : {}),
            ...(lastRow ? styles.lastRowCell : {}),
          }}
        >
          <Typography>{getPrice()}</Typography>
          {cartItem.alterationItems.map((item, index) => (
            <Box sx={{ mt: "0.571428571rem" }} key={index}>
              <Typography>
                {formatCurrency({
                  amount: cartItem.qty * (item.unitPrice || 0),
                })}
              </Typography>
            </Box>
          ))}
          {cartItem.upcharges.map((upcharge, index) => (
            <Box sx={{ mt: "0.571428571rem" }} key={index}>
              <Typography>
                {formatCurrency({
                  amount: upcharge.subUpcharge.price
                    ? cartItem.qty * upcharge.subUpcharge.price
                    : 0,
                })}
              </Typography>
            </Box>
          ))}
          {cartItem.spots.map((spot, index) => (
            <Box sx={{ mt: "0.571428571rem" }} key={index}>
              <Typography>
                {formatCurrency({
                  amount: spot.subSpot.price
                    ? cartItem.qty * spot.subSpot.price
                    : 0,
                })}
              </Typography>
            </Box>
          ))}
          {cartItem.instructions.length > 0 && (
            <Box sx={{ display: "flex" }}>&nbsp;</Box>
          )}
          {cartItem.damages.length > 0 && (
            <Box sx={{ display: "flex" }}>&nbsp;</Box>
          )}
          <Box
            sx={{
              height: "0.07rem",
              width: "100%",
              background: Colors.ACCENT_GRAY_DISABLED,
              display: lastRow ? "none" : "block",
              zIndex: 2,
            }}
          ></Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CartRow;
