import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponseSingle } from "../../typings/interfaces";
import { IcustomerLatestBillDetailResponse } from "../../typings/interfaces";
import { GET_CUSTOMER_LATEST_BILL_DETAILS } from "../../configs/apiConfig";

export const getAllCustomerLatestBillDetailsAction = createAsyncThunk(
    "customerLatestBillDetails/all-customerLatestBillDetails",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await axios.get<BaseResponseSingle<IcustomerLatestBillDetailResponse>>(
                GET_CUSTOMER_LATEST_BILL_DETAILS(id)
            );
            return response.data;
        }
        catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
)
